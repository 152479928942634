import mixpanel from 'mixpanel-browser';
import { MIXPANEL_ID } from '../config/environment';

export const initializeMixpanel = () => {
  if (import.meta.env.VITE_DISABLE_VENDORS === 'true') {
    return mixpanel.init('PEKOPEKOPEKO');
  }
  const debug = import.meta.env.VITE_APP_ENV !== 'prod';
  return mixpanel.init(MIXPANEL_ID, { debug, persistence: 'localStorage' });
};
