import { useMutation, useQuery } from '@tanstack/react-query';
import { isNull, isNumber, omitBy } from 'lodash';
import moment from 'moment';
import { invalidate, success } from 'services/auth';
import { camelcaseKeys } from 'utils/formatting';
import { fetch } from 'whatwg-fetch';
import { ENDPOINT } from '../../config/environment';
import { patch, post } from '../../services/fetch';
import { AuthSchema } from '../schemas';

export function useAuthWithEmail() {
  return useMutation(
    async ({ email, password }: { email: string; password: string }) => {
      const request = {
        headers: {
          accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ auth: { email, password } }),
        method: 'POST',
        credentials: 'include',
      };
      const response = await fetch(`${ENDPOINT}/auth/sign_in`, request);
      const data = camelcaseKeys(await response.json(), { deep: true });
      if (!response.ok) {
        throw data;
      }
      return data;
    },
    {
      onSuccess: data => {
        success(data);
      },
      onError: ({ errors }) => {
        invalidate();
        return errors;
      },
    }
  );
}
export const authWithToken = async (token: string) => {
  const request = {
    headers: {
      accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'POST',
  };
  const response = await fetch(`${ENDPOINT}/users/me`, request);
  const data = camelcaseKeys(await response.json(), { deep: true });
  if (!response.ok) {
    throw data;
  }
  return data as AuthSchema;
};
export function generateAuthQueryKey() {
  return ['users', 'me'];
}
export function useAuthenticate({
  token,
  onSuccess,
  refetchOnMount = false,
  initialData,
  shouldInvalidate = true,
}: {
  refetchOnMount: boolean;
  initialData?: AuthSchema | null;
  onSuccess?: (data: AuthSchema) => void;
  shouldInvalidate?: boolean;
  token?: string | null;
}) {
  return useQuery(
    generateAuthQueryKey(),
    async () => {
      try {
        const request = {
          headers: {
            accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            Authorization: token ? `Bearer ${token}` : null,
          },
          method: 'POST',
          credentials: 'include',
        };
        const response = await fetch(`${ENDPOINT}/users/me`, {
          ...request,
          headers: omitBy(request.headers, isNull),
        });
        const data: AuthSchema = camelcaseKeys(await response.json(), { deep: true });
        if (!response.ok) {
          throw data;
        }
        success(data);
        onSuccess && onSuccess(data);
        return data;
      } catch {
        if (shouldInvalidate) {
          invalidate();
        }
        return null;
      }
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      refetchInterval: auth => {
        if (window.isPlaywright || !auth) return false;
        const expiresAt = auth.session.expiresAt;
        const tokenExpiry = isNumber(expiresAt) ? moment.unix(expiresAt) : moment(expiresAt); // BE returns the date in epoch if it's 6sense auth :(. will ask them to standardize it, but this is a workaround for now
        const now = moment();
        const timeDifference = tokenExpiry.diff(now) - 60000; // we want to refetch a new token one minute before this current one expires
        return Math.max(timeDifference, 10000); // setting the minimum interval to 10 seconds
      },
      initialData,
      refetchOnMount,
    }
  );
}

export function useAuthPasswordless() {
  return useMutation(
    async (email: string) => {
      const response = await post('/passwordless/sign_in', { email, const: 'user' });
      return response;
    },
    {
      onError: ({ errors }) => {
        invalidate();
        return errors;
      },
    }
  );
}
export const requestForNewPassword = async (email: string) => {
  const payload = { email, const: 'user' };
  await patch('/auth/reset', payload);
};
export const setPassword = async (email: string, token: string, password: string) => {
  const payload = {
    email,
    token,
    password,
    passwordConfirmation: password,
    const: 'user',
  };
  const response = await post('/reset_password', payload);
  return response;
};
export const resetPassword = async (payload: {
  const: string;
  password: string;
  passwordConfirmation: string;
  token: string;
}) => {
  const response = await post(`/reset_password`, payload);
  return response;
};

interface UpdatePasswordPayload {
  oldPassword: string;
  password: string;
  passwordConfirmation: string;
}
export const updatePassword = async (
  id: number,
  updatePasswordPayload: UpdatePasswordPayload
): Promise<string> => {
  const payload = { user: updatePasswordPayload };
  const r = await post(`/users/${id}/update_password`, payload);
  return r.token;
};
export function useSignOut() {
  return useMutation(async () => {
    await post(`/auth/sign_out`);
  });
}
export async function authSwitchTeam(teamId: number | string) {
  const response = await post('/auth/switch_teams', { teamId });
  return response as AuthSchema;
}
