import { ChevronDown, ChevronUp } from '@saleswhale/barnacle/icons';
import { ReactElement, useEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { Spinner } from '../Spinner';
import styles from './SubCard.module.scss';

interface Props {
  children: ReactElement[];
  disabled?: boolean;
  isLoading?: boolean;
  testId?: string;
}
interface Element {
  children: ReactElement;
}

SubCard.Body = ({ children }: Element) => children;
SubCard.Header = ({ children }: Element) => children;

export function SubCard({ children, isLoading, testId = 'SubCard' }: Props) {
  const [height, setHeight] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [header, setHeader] = useState<ReactElement | null>(null);
  const [body, setBody] = useState<ReactElement | null>(null);
  const contentElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let element: HTMLDivElement;
    let observer: ResizeObserver;
    if (contentElement.current) {
      element = contentElement.current;
      const updateMaxHeight = (entries: ResizeObserverEntry[]) => {
        if (isActive) {
          for (const entry of entries) {
            if (entry.contentBoxSize) {
              // Firefox implements `contentBoxSize` as a single content rect, rather than an array
              const contentBoxSize = Array.isArray(entry.contentBoxSize)
                ? entry.contentBoxSize[0]
                : entry.contentBoxSize;
              setHeight(contentBoxSize.blockSize);
            }
          }
        } else {
          setHeight(0);
        }
      };
      observer = new ResizeObserver(updateMaxHeight);
      observer.observe(contentElement.current);
    }
    return () => observer.unobserve(element);
  }, [isActive]);

  useEffect(() => {
    children.forEach(child => {
      child.type === SubCard.Header && setHeader(child);
      child.type === SubCard.Body && setBody(child);
    });
  }, [children]);

  return (
    <div className={styles.Container} data-testid={testId}>
      <div
        className={styles[`${isActive ? 'HeaderActive' : 'Header'}`]}
        data-testid={`${testId}__header`}
        onClick={() => setIsActive(!isActive)}
      >
        <div className={styles.Header__content}>{header}</div>
        {isActive ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </div>
      <div
        className={`${styles.Body} ${isActive ? styles.Body__visible : ''}`}
        data-testid={`${testId}__body`}
        style={{ maxHeight: `${height}px`, overflow: isActive ? 'visible' : 'hidden' }}
      >
        <div className={isActive ? styles.Content : styles.Content__hidden} ref={contentElement}>
          {isLoading ? (
            <div className={styles.Spinner}>
              <Spinner size="large" />
            </div>
          ) : (
            body
          )}
        </div>
      </div>
    </div>
  );
}
