import { startCase } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { SessionSchema, TeamSchema } from '../app/schemas';
import { AnalyticsEventSchema } from '../app/schemas/analytics';
import { checkIfSpecialRouteForAnalytics, formatRouteName } from '../utils/routeUtils';

//Events Documentation: https://www.notion.so/saleswhale/Events-Documentation-31e1851143eb43f9b18be94fbc9d97cd
/**
 * Boots up mixpanel with relevant user profiles and event properties
 * This is where we attach a user with outgoing events so mixpanel can properly track on a user level
 * Also where we set super properties; event properties that gets fired everytime an event occurs
 * Useful not only for better data breakdown but also less implementation when sending individual events
 * Also where we set people properties once which are data associated for the current user but can only be set once and not be updated subsequently
 * Useful for data we want to track that doesn't get changed often, e.g. createdAt, email etc
 */
export const bootMixpanel = (session?: SessionSchema) => {
  /**
   * As we don't want to track narwhal sessions and cypress testing, we opt out of tracking here
   * However this actually prevents mixpanel from tracking locally as cypress uses your localhost
   * To be able to debug mixpanel, switch the vendor condition
   * A bit of a hassle but the price we have to pay for cypress. Could always reintroduce pekora altho semantically incorrect
   * Also added an extra layer of protection for our ci. This is to prevent mixpanel from ever firing in the testing environment in the case we forget to flip the switch here
   **/
  if (!session || import.meta.env.VITE_DISABLE_VENDORS === 'true') {
    return mixpanel.opt_out_tracking();
  }
  const currentTeam = session.teams.find(
    team => team.id && team.id === session.currentTeam.id
  ) as TeamSchema; // assume we find it
  mixpanel.identify(session.userId.toString());
  //Sets super properties for events
  mixpanel.register({
    account: session.currentTeam.name,
    email: session.email,
    role: session.membershipRole,
    user_id: session.userId,
    user_name: `${session.firstName} ${session.lastName || ''}`.trim(),
    account_id: session.currentTeam.id,
    subscription_type: currentTeam.subscriptionType,
  });
  //Sets people properties only once for users
  mixpanel.people.set_once({
    $first_name: session.firstName,
    $last_name: session.lastName || '',
    $name: `${session.firstName} ${session.lastName || ''}`.trim(),
    created_at: session.membershipCreatedAt,
    $email: session.email,
    account: session.currentTeam.name,
    account_id: session.currentTeam.id,
    role: session.membershipRole,
    //TODO: Figure out if we want to add First Login here
  });
  mixpanel.people.set({
    subscription_type: currentTeam.subscriptionType, //As this might change when team becomes paid, separting this from .set_once
  });
};
/**
 * Main handler for tracking events
 * To try to make it easier and less confusing when implementing events, opting to use one function instead of multiple feature functions
 * Also product wants the ability to be able to group certain events
 * This would make it so that each group would have their each unique properties
 * https://www.notion.so/saleswhale/Event-Objects-and-Groups-Documentation-8daa5edf65494287b28e37923e6b880c
 *
 * TODO: Figure out an easy and less hassle way of passing down group properties
 * - Currently we'll have to pass the properties everytime for a new event
 */
export const sendEvent = ({ event, type, group, properties }: AnalyticsEventSchema) => {
  mixpanel.track(event, {
    type: type,
    group: group,
    ...properties,
  });
};
/**
 * Generates an AnalyticsEventObject for page views
 * Mainly used for location changes in App.tsx
 * Went with this approach so we don't have to rely on referencing a huge dictionary which will cut down the hassle of writing events
 * Caveats:
 * - Every Page visit will now be tracked; even non-existent ones
 * - For special naming of events + groups, we'll have to manually condition them; this might balloon up in the future
 */
export const generatePageEventObject = (path: string): AnalyticsEventSchema => {
  const routeName = formatRouteName(path);
  let event = `View ${startCase(routeName.replace(/[/_]/g, ' '))}`;
  const group = routeName.split('/')[0];

  /**
   * Since dynamic routes get formatted without their ids, a problem occurs with our event naming
   * E.g. attachments/1 would be View Attachments which will be the same as attachments -> View Attachments
   * To fix this, the voodoo below checks:
   * - If the parent route is the routeName
   * - If the next nested route is an integer (:id)
   * - If path is only in the form of /routeName/:id
   */
  if (
    path.split('/')[1] === routeName &&
    /^\d+$/.test(path.split('/')[2]) &&
    path.split('/').length === 3
  ) {
    event = `${event} Index`; //Append Index to event name; View Attachments -> View Attachments Index
  }
  const { eventName, groupName } = checkIfSpecialRouteForAnalytics({ event, group, routeName });

  return {
    event: eventName,
    group: groupName,
    type: 'page',
  };
};
