import { SessionSchema } from 'app/schemas';
import { init } from 'commandbar';
import { COMMANDBAR_TOKEN } from 'config/environment';

export function bootCommandbar(session?: SessionSchema) {
  try {
    if (import.meta.env.VITE_DISABLE_VENDORS === 'true') {
      return;
    }

    init(COMMANDBAR_TOKEN);
    const id = session?.userId.toString() || 'CE';
    window.CommandBar.boot(id);
  } catch {
    return;
  }
}

export function invalidateCommandbar() {
  try {
    window.CommandBar.shutdown();
  } catch {
    return;
  }
}

export function openCommandbar() {
  try {
    window.CommandBar.toggleHelpHub();
  } catch {
    return;
  }
}
