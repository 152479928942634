// @ts-nocheck
import { PENDO_TOKEN } from '../config/environment';

function initializePendo() {
  if (import.meta.env.VITE_DISABLE_VENDORS === 'true') return;
  const existingScript = document.getElementById('pendoScript');
  if (existingScript) return;
  const script = document.createElement('script');
  script.id = 'pendoScript';
  document.body.appendChild(script);

  (function (apiKey) {
    (function (p, e, n, d, o) {
      let v, w, x, y, z;
      o = p[d] = p[d] || {};
      o._q = o._q || [];
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
      for (w = 0, x = v.length; w < x; ++w)
        (function (m) {
          o[m] =
            o[m] ||
            function () {
              o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
            };
        })(v[w]);
      y = e.createElement(n);
      y.async = !0;
      y.src = 'https://content.pendo.6sense.com/agent/static/' + apiKey + '/pendo.js';
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })(window, document, 'script', 'pendo');
  })(PENDO_TOKEN);
}

export default initializePendo;
